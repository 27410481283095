<template>
  <div class="bg full-maps">
    <div id="fengmap" @click="weituo"></div>
    <div class="bg-mask"></div>
    <div class="bg-mask1"></div>
    <div class="left-bar" v-show="isOpen">
      <img src="@/assets/full-menu.png" class="img-menu" @click="isOpen = false">
      <div class="ul-group">
        <div
          class="li-group"
          v-for="item in groupList"
          :key="item.group_id"
          :class="{act: group_id === item.group_id}"
          @click="checkGroup(item)"
        >
          <img :src="item.group_id > 0 ? domain + item.icon : item.icon" class="img-group">
          <div class="txt-desc">{{item.name}}</div>
        </div>
      </div>
      <div class="change" @click="linkBig">
        大屏切换
        <img src="@/assets/changeSceen.png" class="img-change">
      </div>
    </div>
    <div class="left-bar2" v-show="!isOpen">
      <img src="@/assets/full-menu.png" class="img-menu close" @click="isOpen = true">
    </div>
    <div class="view-bar" v-show="viewOpen">
      <div class="header">
        <a-icon type="arrow-left" @click="viewOpen = !viewOpen" />
        收起视图弹窗
      </div>
      <div class="ul-check">
        <div class="li" v-for="(item, p) in deciveIconList" :key="p">
          <down-outlined v-if="typeOpen[p]" @click="typeOpen[p] = false" />
          <right-outlined v-if="!typeOpen[p]" @click="typeOpen[p] = true" />
          <span class="type-name">{{typeMap[p]}}</span>
          <a-checkbox
            :checked="checkAll[p]"
            @change="onCheckAllChange(p)"
          />
          <div v-if="typeOpen[p]" class="ul-check-group">
            <a-checkbox-group :value="checkedList[p]" @change="changeCheck(p)">
              <div class="li" v-for="i in item" :key="i.dictionary_id">
                <div class="name">{{i.device_type_name}}</div>
                <a-checkbox :value="i.dictionary_id"></a-checkbox>
              </div>
            </a-checkbox-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fengmap from 'fengmap/build/fengmap.map.min.js'
import 'fengmap/build/fengmap.plugin.min.js'
import {
	domain
} from '@/siteInfo.js'

export default {
  data() {
    return {
			domain,
			query: {
				floor_id: 1,
				limit: 9999
			},
			markers: [],
      slide: 17,
      deciveIconList: [],
      icons: [],
      groupList: [
        {
          group_id: '-1',
          name: '视图控制',
          icon: require('@/assets/view.png')
        }
      ],
      group_id: '-2',
      iconMap: {},
      isOpen: true,
      viewOpen: false
    }
  },
	created() {
		this.$post(domain + '/admin/v1/dictionary/groupList').then(({data}) => {
			this.groupList = [...data.list, ...this.groupList]
		})
	},
  mounted() {
    const options = {
      appName: '大理卷烟厂复烤厂',
      key: '48874e0607f3c1517b7bc752ee37ed81',
      mapID: '1584485960495415298',
      container: document.getElementById('fengmap'),
      themeID: '1588152997390413826',
      viewMode: fengmap.FMViewMode.MODE_3D,
      zoomRange: [16, 22],
      highlightPicker: []
    }
    this.map = new fengmap.FMMap(options)
    this.map.on('click', event => {
			const coords = event.coords
			if (coords.x && coords.y && coords.z) {
				const mapCoordinate = fengmap.FMCalculator.mapCoordinateToLatlng(coords)
				this.wz.lon = mapCoordinate.x
				this.wz.lat = mapCoordinate.y
				// alert(`未点击到设备，经度：${mapCoordinate.x}纬度：${mapCoordinate.y}`)
				if (this.markerSz.remove) {
					this.markerSz.remove()
				}
				this.markerSz = new fengmap.FMDomMarker({
					content: `<div class="mybkkd"></div>`,
					x: coords.x,
					y: coords.y
				})
				this.markerSz.addTo(this.floor)
				this.listvisible = true
			} else {
				alert('未点击到地图位置')
			}
    })
		this.map.on('loaded', () => {
			this.map.setZoom({
				zoom: 17
			})
			this.level = this.map.getLevel()
			this.floor = this.map.getFloor(this.level)
			this.lclist = this.map.getFloorInfos()
			console.log(this.lclist)
			this.getList()
		})
  },
  methods: {
    linkBig() {
			this.$router.push('/maps')
    },
		checkGroup(item) {
			this.group_id = item.group_id
			if (item.group_id === '-1') {
				this.viewOpen = true
			} else {
				this.viewOpen = false
				this.$post(domain + '/admin/v1//dictionary/groupInfo', {
					group_id: item.group_id
				}).then(res => {
					this.group_list = res.data.groupList
					this.getList()
				})
			}
		},
		weituo(e) {
			if (e.target.className === 'feng-icon-div') {
				// this.bind_id = e.target.dataset.id
				this.getInfo(e.target.dataset.id)
				this.infovisible = true
				// alert('点中设备，id为：' + e.target.dataset.id)
			} else {
				this.notbindList()
			}
		},
		getList() {
  		let dictionary_ids
			if (this.group_id > 0) {
				dictionary_ids = this.groupList
			} else {
				dictionary_ids = []
			}
			this.$post(domain + '/admin/v1/device/bindList', {
				...this.query,
				dictionary_ids
			}).then(res => {
				if (res.code === 0) {
					this.markers.map(item => {
						item.remove()
					})
					this.markers = []
					res.data.list.map(item => {
						if (Math.floor(item.lat) === 25 && Math.floor(item.lon) === 100) {
							const latlngToMap = fengmap.FMCalculator.latlngToMapCoordinate({
								x: item.lon,
								y: item.lat
							})
							const marker = new fengmap.FMDomMarker({
								content: `<div class="feng-icon-div" data-id="${item.device_id}">
										<img class="img-fengmap-icon" src="${domain}${item.icon}">
									</div>`,
								x: latlngToMap.x,
								y: latlngToMap.y
							})
							console.log('这里执行了吗？')
							console.log(marker, this.floor)
							marker.addTo(this.floor)
							this.markers.push(marker)
						}
					})
				} else {
					this.$message.error(res.msg)
				}
			})
		}
  }
}
</script>

<style lang="less" scoped>
@h: 10.8;
.bg {
  position: absolute;
  width: 100%;
  height: 100%;
}
#fengmap {
  position: absolute;
  top: -50vh/@h;
  left: 0;
  right: 0;
  bottom: -50vh/@h;
}
.left-bar {
  position: absolute;
	top: 0;
  left: 0;
  width: 96vh/@h;
  height: 100%;
  background: #202125;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.left-bar2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 96vh/@h;
  height: 96vh/@h;
  border-radius: 0 0 32vh/@h 0;
  background: #202125;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.view-bar {
  position: absolute;
  left: 96vh/@h;
  top: 0;
  width: 340vh/@h;
  height: 100%;
  background-color: #19191B;
  overflow-y: auto;
  .header {
    height: 52vh/@h;
    background-color: rgba(0, 0, 0, 0.38);
    line-height: 52vh/@h;
    color: #fff;
    font-size: 14vh/@h;
    text-indent: 20vh/@h;
  }
}
.img-menu {
  width: 32vh/@h;
  height: 32vh/@h;
  margin-top: 32vh/@h;
  cursor: pointer;
  &.close {
    transform: rotateY(180deg);
  }
}
.change {
  position: absolute;
  bottom: 24vh/@h;
  display: flex;
  left: 12vh/@h;
  align-items: center;
  font-size: 14vh/@h;
  color: rgba(255,255,255,0.85);
  cursor: pointer;
  .img-change {
    width: 12vh/@h;
    height: 12vh/@h;
    margin-left: 4vh/@h;
    filter: drop-shadow(rgba(255,255,255,0.85) 100px 0);
    transform: translateX(-100px);
  }
  &:hover {
    color: #7AF4FF;
    .img-change {
      filter: drop-shadow(#7AF4FF 100px 0);
      transform: translateX(-100px);
    }
  }
}
.ul-group {
  margin-top: 40vh/@h;
}
.li-group {
  width: 48vh/@h;
  height: 105vh/@h;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:first-child {
    border: none;
  }
  &.act {
    .img-group {
      filter: drop-shadow(#7AF4FF 100px 0);
      transform: translateX(-100px);
    }
    .txt-desc {
      color: #7AF4FF;
    }
  }
}
.img-group {
  width: 32vh/@h;
  height: 32vh/@h;
  margin-top: 24vh/@h;
  filter: drop-shadow(#6B6B6B 100px 0);
  transform: translateX(-100px);
}
.txt-desc {
  white-space: nowrap;
  font-size: 12vh/@h;
  height: 17vh/@h;
  line-height: 17vh/@h;
  margin-top: 8vh/@h;
  color: #6B6B6B;
}

</style>

<style lang="less">
.full-maps {
	.mybkkd {
		background-color: #fff;
		height: 30px;
		position: relative;
		width: 2px;
		left: 14px;
	}

	.mybkkd:after {
		background: #fff;
		content: "";
		height: 2px;
		position: absolute;
		left: -14px;
		top: 14px;
		width: 30px;
	}

	.feng-icon-div {
		width: 30px;
		height: 30px;
		overflow: hidden;
		background-size: 100% 100%;
		cursor: pointer;
		border-radius: 50%;
		border: 5px solid #333;
		background-color: #fff;
		box-sizing: content-box;
		margin-left: -5px;
		margin-top: -5px;

		&:hover {
			border-color: #2B6AE0;

			.img-fengmap-icon {
				filter: drop-shadow(#2B6AE0 100px 0);
			}
		}
	}

	.img-fengmap-icon {
		width: 24px;
		height: 24px;
		margin-left: 3px;
		margin-top: 3px;
		filter: drop-shadow(#333 100px 0);
		transform: translateX(-100px);
		pointer-events: none;
		user-select: none;
	}

	.slider {
		margin-bottom: 16px;
	}
}
</style>
